<template>
  <b-card class="shop-agents">
    <b-row>
      <b-col cols="12">
        <div class="row">
          <div class="col-12 text-center">
            <div v-if="storeAgentPricing.price_type === 'BUCKET'" class="alert alert-warning p-1">
              <div>
                <strong>
                  <h3>Bucket Pricing</h3>
                </strong>
              </div>
              <div class="text-dark">1st Bucket Base Price will be considered as Variable Pay</div>
              <div class="text-dark">Every additional bucket will be added under Distance Pay</div>
            </div>
            <div v-if="storeAgentPricing.price_type === 'LINEAR'" class="alert alert-warning p-1">
              <div>
                <strong>
                  <h3>Linear Pricing</h3>
                </strong>
              </div>
              <div class="text-dark">Base Price will be considered as Variable Pay</div>
              <div class="text-dark">Price per additional km is added under Distance Pay</div>
            </div>
          </div>
        </div>
        <div class="row">
          <form @submit.prevent="saveSettings">
            <b-col cols="12">
              <b-row v-if="storeAgentPricing">
                <b-col cols="12" md="6">
                  <b-form-group>
                    <label class="form-extra-label"
                      >{{ $t('Pricing Type') }} <span>({{ $t('Select Bucket or Linear Pricing') }})</span></label
                    >
                    <b-form-select v-model="storeAgentPricing.price_type" :options="priceTypes" @change="pricingChange" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" class="d-flex align-items-center">
                  <b-form-checkbox v-model="pricingModeApi" switch inline disabled>
                    <span>{{ pricingModeApi ? $t('Bucket Pricing') : $t('Linear Pricing') }} ({{ $t('Pricing set currently for the Shop') }})</span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>
            <div v-if="storeAgentPricing && storeAgentPricing.price_type === 'BUCKET'" class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <div v-for="(item, index) of bucketPricings" :key="index" class="row">
                    <div class="col-md-2">
                      <b-form-group label="">
                        <label class="form-extra-label">{{ $t('Base Price') }}</label>
                        <b-form-input v-model="item.price" :min="!isEmpty(bucketPricings[index-1]) ? parseInt(bucketPricings[index-1].price) : 1"  type="number" @keypress="$superApp.validations.numberOnly" />
                      </b-form-group>
                    </div>
                    <div class="col-md-2">
                      <b-form-group label="">
                        <label class="form-extra-label"
                          >{{ $t('Unit') }} <span>({{ $t('Kg/Km') }})</span></label
                        >
                        <b-form-select v-model="item.unit" :options="unitTypes" :disabled="index != 0" @change="unitChanged" />
                      </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group label="">
                        <label class="form-extra-label"
                          >{{ item.unit == 'KG' ? $t('Weight from') : $t('Kilometer from') }} <span>({{ item.unit }})</span></label
                        >
                        <b-form-input v-if="item.unit == 'KG'" v-model="item.from_kg" type="number" @keypress="$superApp.validations.numberOnly" :disabled="!isEmpty(bucketPricings[index-1])" />
                        <b-form-input v-else v-model="item.from_km" type="number" @keypress="$superApp.validations.numberOnly"  :disabled="!isEmpty(bucketPricings[index-1])" />
                      </b-form-group>
                    </div>
                    <div class="col-md-2">
                      <b-form-group label="">
                        <label class="form-extra-label"
                          >{{ item.unit == 'KG' ? $t('Weight to') : $t('Kilometer to') }} <span>({{ item.unit }})</span></label
                        >
                        <b-form-input v-if="item.unit == 'KG'" :min="!isEmpty(item.from_kg) ? parseInt(item.from_kg) : 1" v-model="item.to_kg" type="number" @keypress="$superApp.validations.numberOnly" />
                        <b-form-input v-else v-model="item.to_km" :min="!isEmpty(item.from_km) ? parseInt(item.from_km) : 1" type="number" @keypress="$superApp.validations.numberOnly" />
                      </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <div class="row">
                        <div class="col-8">
                          <b-form-group label="">
                            <label class="form-extra-label">{{ item.unit == 'KG' ? 'KM' : 'KG' }} {{ $t('Included') }}</label>
                            <b-form-input v-if="item.unit == 'KG'" v-model="item.to_km" type="number" @keypress="$superApp.validations.numberOnly" />
                            <b-form-input v-else v-model="item.to_kg" type="number" @keypress="$superApp.validations.numberOnly" />
                          </b-form-group>
                        </div>
                        <div class="col-4 d-flex align-items-center p-0">
                          <button v-if="bucketPricings.length - 1 == index" class="btn btn-primary mr-1 px-1" :disabled="item.unit == 'KG' ? (item.from_kg == item.to_kg) : (item.from_km == item.to_km)" @click="addBucketList">
                            <feather-icon icon="PlusIcon" />
                          </button>
                          <button v-if="bucketPricings.length != 1" class="btn btn-primary px-1" @click="removeBucketList(index)">
                            <feather-icon icon="MinusIcon" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <b-col cols="12" md="12" class="text-center">
                  <b-button variant="primary" type="submit">
                    {{ $t('Save Settings') }}
                  </b-button>
                </b-col>
              </div>
            </div>
            <div v-if="storeAgentPricing && storeAgentPricing.price_type === 'LINEAR'" class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-2">
                      <b-form-group label="">
                        <label class="form-extra-label">{{ $t('Base Price') }}</label>
                        <b-form-input v-model="storeAgentPricing.delivery_charge" type="number" @keypress="$superApp.validations.numberOnly" />
                      </b-form-group>
                    </div>
                    <div class="col-md-2">
                      <b-form-group label="">
                        <label class="form-extra-label"
                          >{{ $t('Unit') }} <span>({{ $t('Kg/Km') }})</span></label
                        >
                        <b-form-select v-model="storeAgentPricing.unit" :options="unitTypes" />
                      </b-form-group>
                    </div>
                    <div class="col-md-2">
                      <b-form-group label="">
                        <label class="form-extra-label">{{ storeAgentPricing.unit == 'KG' ? $t('KG Included') : $t('KM Included') }}</label>
                        <b-form-input v-model="storeAgentPricing.base_distance" value="0" type="number" @keypress="$superApp.validations.numberOnly" />
                      </b-form-group>
                    </div>
                    <div class="col-md-2">
                      <b-form-group label="">
                        <label class="form-extra-label"
                          >{{ $t('Price per additional') }} <span>({{ storeAgentPricing.unit }})</span></label
                        >
                        <b-form-input v-model="storeAgentPricing.distance_price" type="number" @keypress="$superApp.validations.numberOnly" />
                      </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group label="">
                        <label class="form-extra-label">{{ storeAgentPricing.unit == 'KG' ? $t('Distance Included (KM)') : $t('Weight Included (KG)') }}</label>
                        <b-form-input v-model="storeAgentPricing.extra" type="number" @keypress="$superApp.validations.numberOnly" />
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <b-col cols="12" md="12" class="text-center">
                  <b-button variant="primary" type="submit">
                    {{ $t('Save Settings') }}
                  </b-button>
                </b-col>
              </div>
            </div>
          </form>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { isEmpty } from '@/utilities'
import { useStoreUi } from './useStore'
import { hideLoader, showDangerNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
export default {
  name: 'AgentPricing',
  data: () => ({
    isDataSubmitted: false,
    storeAgentPricing: {},
    bucketPricings: [],
    pricingModeApi: false,
    priceTypes: [
      {
        text: 'Bucket Pricing',
        value: 'BUCKET',
      },
      {
        text: 'Linear Pricing',
        value: 'LINEAR',
      },
    ],
    unitTypes: [
      {
        text: 'Kg',
        value: 'KG',
      },
      {
        text: 'Km',
        value: 'KM',
      },
    ],
  }),
  computed: {},
  methods: {
    isEmpty,
    unitChanged() {
      const { unit } = this.bucketPricings[0]
      this.bucketPricings.forEach(x => {
        x.unit = unit
      })
    },
    pricingChange() {
      if (this.bucketPricings.length === 0) {
        this.addBucketList()
      }
    },
    addBucketList() {
      const firstItem = this.bucketPricings.find((i, idx) => idx === 0)
      const lastItem = this.bucketPricings.find((i, idx, self) => idx + 1 === self.length)
      const item = {
        from_kg: lastItem ? parseInt(lastItem.to_kg) : 0,
        to_kg: lastItem ? parseInt(lastItem.to_kg) : 0,
        kg_min_from: lastItem ? parseInt(lastItem.to_kg) : 1,
        from_km: lastItem ? parseInt(lastItem.to_km) : 0,
        to_km: lastItem ? parseInt(lastItem.to_km) : 0,
        km_min_from: lastItem ? parseInt(lastItem.to_km) : 1,
        price: lastItem ? parseInt(lastItem.price) : 0,
        unit: firstItem ? firstItem.unit : this.storeAgentPricing.unit,
      }
      this.bucketPricings.push(item)
    },
    removeBucketList(index) {
      this.bucketPricings = this.bucketPricings.filter((_, i) => i !== index)
    },
    saveSettings() {
      const { saveAgentPricingSettings } = useStoreUi()
      this.isDataSubmitted = true
      setTimeout(() => {
        if (window.SuperApp.validations.anyErrorInModal(false)) {
          return
        }
        this.isDataSubmitted = false
        const formData = {}
        formData.price_type = this.storeAgentPricing.price_type
        formData.delivery_charge = this.storeAgentPricing.delivery_charge
        formData.bucket = this.bucketPricings
        formData.base_distance = this.storeAgentPricing.base_distance
        formData.unit = this.storeAgentPricing.unit
        formData.extra = this.storeAgentPricing.extra
        formData.default_weight = this.storeAgentPricing.default_weight
        formData.distance_price = this.storeAgentPricing.distance_price
        showLoader()
        saveAgentPricingSettings(formData)
          .then(({ data }) => {
            hideLoader()
            if (data.statusCode === '200') {
              showSuccessNotification(this, data.message)
              this.shopAgentPricingInfo()
            } else {
              showDangerNotification(this, data.message)
            }
          })
          .catch(error => {
            showDangerNotification(this, error)
          })
      })
    },
    async shopAgentPricingInfo() {
      if (!window.SuperApp.getters.isStore()) {
        hideLoader()
        return
      }
      const { shopAgentPricingInfo } = useStoreUi()
      showLoader()
      await shopAgentPricingInfo()
        .then(({ data }) => {
          hideLoader()
          this.storeAgentPricing = data.responseData
          if (isEmpty(this.storeAgentPricing)) {
            this.storeAgentPricing = {
              base_distance: null,
              price_type: null,
              unit: null,
              extra: null,
            }
          }
          this.pricingModeApi = this.storeAgentPricing.price_type === 'BUCKET'
          if (this.storeAgentPricing.bucket_prices) {
            const pricing = JSON.parse(this.storeAgentPricing.bucket_prices)
            if (pricing.length === 0) {
              if (this.storeAgentPricing.price_type === 'BUCKET') {
                this.bucketPricings = []
                this.addBucketList()
              }
            } else {
              const mapPricing = pricing.map(x => ({
                ...x,
                unit: x.unit ? x.unit : this.storeAgentPricing.unit,
              }))
              this.bucketPricings = mapPricing
            }
          } else {
            this.bucketPricings = []
            this.addBucketList()
          }
        })
        .catch(error => {
          showDangerNotification(this, error)
        })
    },
  },
  async mounted() {
    showLoader()
    await this.shopAgentPricingInfo()
    hideLoader()
  },
}
</script>